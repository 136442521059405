import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"]

  onFrameRender(event) {
    this.linkTargets.forEach((link) => {
      link.setAttribute("aria-current", link.href === event.target.src)
    })
  }
}
