import "@hotwired/turbo-rails"

import "@asgerb/apparatus/application"

import "~/controllers"

if (!window.scrollPositions) {
  window.scrollPositions = {}
}

document.addEventListener("turbo:before-fetch-request", (event) => {
  const { url, fetchOptions } = event.detail

  if (url.searchParams.get("close_layer")) {
    fetchOptions.headers["X-CLOSE-LAYER"] = "true"
    const modifiedURL = new URL(url)
    modifiedURL.searchParams.delete("close_layer")
    event.detail.url = modifiedURL
  }
})

function preserveScroll() {
  document.querySelectorAll("[data-preserve-scroll]").forEach((element) => {
    scrollPositions[element.id] = element.scrollTop
  })
}

function restoreScroll(event) {
  document.querySelectorAll("[data-preserve-scroll]").forEach((element) => {
    element.scrollTop = scrollPositions[element.id]
  })

  if (!event.detail.newBody) return
  // event.detail.newBody is the body element to be swapped in.
  // https://turbo.hotwired.dev/reference/events
  event.detail.newBody
    .querySelectorAll("[data-preserve-scroll]")
    .forEach((element) => {
      element.scrollTop = scrollPositions[element.id]
    })
}

window.addEventListener("turbo:before-cache", preserveScroll)
window.addEventListener("turbo:before-render", restoreScroll)
window.addEventListener("turbo:render", restoreScroll)

Turbo.FrameElement.delegateConstructor.prototype.proposeVisitIfNavigatedWithAction =
  function (frame, action = null) {
    this.action = action

    if (this.action) {
      const pageSnapshot = Turbo.PageSnapshot.fromElement(frame).clone()
      const { visitCachedSnapshot } = frame.delegate

      frame.delegate.fetchResponseLoaded = (fetchResponse) => {
        if (frame.src) {
          const { statusCode, redirected } = fetchResponse
          const responseHTML = frame.ownerDocument.documentElement.outerHTML
          const response = { statusCode, redirected, responseHTML }
          const options = {
            response,
            visitCachedSnapshot,
            willRender: false,
            updateHistory: false,
            restorationIdentifier: this.restorationIdentifier,
            snapshot: pageSnapshot
          }

          if (this.action) options.action = this.action

          Turbo.session.visit(frame.src, options)
        }
      }
    }
  }
