import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "name", "image"]
  static classes = ["preview"]

  open() {
    this.inputTarget.click()
  }

  fileSelected(event) {
    const file = event.target.files[0]
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const image = this.imageTarget
        image.removeAttribute("srcset")
        image.src = e.target.result
      }
      reader.readAsDataURL(file)
    }
    if (file) {
      this.element.dataset.hasFile = ""
      this.nameTarget.textContent = file.name
    }
  }
}
